import Link from 'components/shared/Link'
import { Text } from 'src/components/typo'
import css from './styles.module.scss'

const NewsWithLink = ({
  link,
  text,
  badgeText,
  newsWithLinkCustomTextColor,
}) => {
  return (
    <div className={css.wrapper}>
      <div className={css.tag}>{badgeText}</div>
      <Text
        theme={
          newsWithLinkCustomTextColor ? newsWithLinkCustomTextColor : 'gray'
        }
      >
        {text}
      </Text>
      <Link {...link} className={css.link} />
    </div>
  )
}

export default NewsWithLink
